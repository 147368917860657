.option {
  /* stubbed for module style */
}

.optionContent {
  /* stubbed for module style */
}

.option:hover .optionContent {
  @apply outline-primary-maincolor-600;
  @apply bg-primary-maincolor-50;
  @apply outline;
}