.progress {
  border-radius: 99em;
  @apply w-full;
  @apply h-3;
  @apply border;
  @apply border-gray-200;
  @apply bg-gray-200;
  @apply relative;
  @apply overflow-hidden;
}

.progress > :global(span) {
  display: inline-block;
  height: 100%;
  width: 100%;
  transition: transform 1s;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  transform: translate(-100%);
}
