@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.ql-toolbar.ql-snow {
  background-color: #EAECF0;
  color: #1D2939;
  border-bottom: 0px;
  border: 1px solid #EAECF0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container.ql-snow {
  border-top: 0px;
  border: 1px solid #EAECF0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #FFFFFF;
}

.quill.has-error .ql-toolbar.ql-snow {
  border-color: #F04438 !important;
}

.quill.has-error .ql-container.ql-snow {
  border-color: #F04438 !important;
}

/* TABLE */
table.tb-app {
  color: #475467;
  @apply text-sm;
  border-collapse: collapse;
}

table.tb-app > thead > tr {
  background-color: #EEFFF4;
}

table.tb-app > thead > tr > td,
table.tb-app > thead > tr > th {
  @apply py-3;
  @apply px-6;
  @apply font-semibold;
}

table.tb-app > tbody > tr > td {
  @apply py-4;
  @apply px-6;
  /* vertical-align: initial; */
}

table.tb-app.tb-striped > tbody > tr:nth-child(even) {
  background-color: #F9FAFB;
}

table.tb-app.tb-striped > tbody > tr:nth-child(odd) {
  background-color: #fff;
}

table.tb-bordered-bottom > tbody > tr {
  border-bottom: solid 1px #EAECF0;
}


.tb-pagination-size-select {
  @apply relative;
}

.tb-pagination-size-select select {
  @apply py-3;
  @apply pl-4;
  @apply pr-8;
  @apply bg-white;
  @apply rounded-lg;
  @apply w-full;
  border: 1px solid #D0D5DD;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.tb-pagination-size-select .select-icon {
  @apply absolute;
  @apply h-full;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-center;
  @apply right-2;
  top: 0;
}

/* QUILL STYLING */
.quill-1.ql-editor, .quill-1 .ql-editor {
  height: 1.5rem;
}

.quill-2.ql-editor, .quill-2 .ql-editor {
  height: 3rem;
}

.quill-3.ql-editor, .quill-3 .ql-editor {
  height: 4.5rem;
}

.quill-4.ql-editor, .quill-4 .ql-editor {
  height: 6rem;
}

.quill-5.ql-editor, .quill-5 .ql-editor {
  height: 7.5rem;
}

.quill-6.ql-editor, .quill-6 .ql-editor {
  height: 9rem;
}

.quill-7.ql-editor, .quill-7 .ql-editor {
  height: 10.5rem;
}

.quill-8.ql-editor, .quill-8 .ql-editor {
  height: 12rem;
}