/* DATEPICKER style override */
/* ============================================================================== */
.app-datepicker :global(.react-datepicker-wrapper .react-datepicker__close-icon) {
  @apply top-0;
  @apply right-8;
  @apply pt-2;
}

.app-datepicker :global(.react-datepicker-wrapper .react-datepicker__close-icon:after) {
  background: transparent;
  @apply text-gray-400;
  @apply text-2xl;
  @apply p-0;
  @apply w-6;
  @apply h-6;
}
