.tb-pagination {
  display: flex;
  flex-wrap: wrap;
}

.tb-pagination :global(> button) {
  @apply px-4 py-2;
  @apply border;
  border-color: #D0D5DD;
  border-left-width: 0;
  text-transform: capitalize;
  cursor: pointer;
}

.tb-pagination :global(> button:first-child) {
  border-left-width: 1px;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tb-pagination :global(> button:last-child) {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.tb-pagination :global(> button:disabled) {
  @apply text-gray-400;
  cursor: not-allowed;
}