.anim-ellipsis::after {
  display: inline-block;
  animation: ellipsize steps(1, end) 1.5s infinite;
  content: '';
}

@keyframes ellipsize {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}


/*
  CSS Pure Loader from https://loading.io/css/
*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 10em; /* 80px */
  height: 10em; /* 80px */
}

.lds-ring :global(div) {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 8em; /* 64px */
  height: 8em; /* 64px */
  margin: 1em; /* 8px */
  border: 1em solid currentColor; /* 8px */
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}

.lds-ring :global(div:nth-child(1)) {
  animation-delay: -0.45s;
}

.lds-ring :global(div:nth-child(2)) {
  animation-delay: -0.3s;
}

.lds-ring :global(div:nth-child(3)) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
