.md\:px-28.flex.flex-col.h-full.mx-auto.relative {
    overflow: hidden;
}

.flex-1.customDateKonsepNote div label.label {
    display: none;
}

.flex-1.customDateKonsepNote div div div div div.relative.mt-2 {
    margin-top: unset;
}

.flex-1.customDateRangeList div label.label {
    display: none;
}

.flex-1.customDateRangeList div div div div div.relative.mt-2 {
    margin-top: unset;
}